.slider {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 2rem;

    .paginator {
        display: none;
    }

    .product {
        padding: 0;
    }
}


.slider--products {
    max-width: 100%; 
    margin-top: .5rem;
   // padding: 0 30px;
   overflow: hidden;
    .owl-nav {
        width: 100%;
    }
    .owl-prev, .owl-next {
        position: absolute;
        top: calc(50% - 35px);

        width: 80px;
        height: 80px;
        background: @backgroundBlock!important;
        //box-shadow: 0px 3px 16px 0px rgba(1, 1, 1, 0.24);
        color: @arrowsColor!important;
        font-size: 24px!important;
        line-height: 40px;
        text-align: center;
        border-radius: 50%!important;
        margin: 0!important;
        transition: all 0.3s ease-in-out;
        overflow: hidden; 
        &:hover {
            box-shadow: 0px 3px 16px 0px rgba(1, 1, 1, 0.24);
        }

    }
    .owl-prev {
        left: -40px;
        display: flex!important;
        justify-content: flex-end;
        align-items: center;
        > i {
            margin-right: 18px;
        } 
    }  
    .owl-next {
        right: -40px; 
        display: flex!important;
        justify-content: flex-start;
        align-items: center;
        > i { 
            margin-left: 18px;
        }
    }
}

