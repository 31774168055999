.box-slider {
    .pageslider {
        .slides {
            li {
                background-size: cover!important;  
                > a {
                    img {
                        @media screen and (min-width: 1440px) {
                            margin-top: 70px;
                        }
                    }
                }
            }
        }

        .ps-arrow-left, .ps-arrow-right {
            width: 116px;
            height: 116px;
            border-radius: 50%;
            margin-top: -3.5em;
            background: rgba(255,255,255,.5)!important;
            display: flex;
            justify-content: center;
            align-content: center;
            transition: .3s ease-in-out;
            &:hover {
                box-shadow: 0px 0px 55px -10px rgba(0,0,0,0.65);
            }
            @media screen and (min-width: 1440px) {
                margin-top: -0.5em;
            }
            @media screen and (max-width: 767px) {
                width: 40px;
                height: 40px;
                margin-top: -1.5em;
            } 
        }
          
        .ps-arrow-left {
            left: -4.5em!important;
            justify-content: flex-end;

            &::before {
                content: "\f104";
                font-family: "Font Awesome 5 Free"!important;
                font-weight: 900!important;
                color: @arrowsColor;
                line-height: 1; 
                font-size: 36px;
                display: flex;
                justify-content: flex-start; 
                align-items: center;
                margin-right: 25px;
            }
        }
        .ps-arrow-right { 
            right: -4.5em!important; 
            justify-content: flex-start;
            &::before {
                content: "\f105";
                font-family: "Font Awesome 5 Free"!important;
                font-weight: 900!important;
                color: @arrowsColor;
                line-height: 1; 
                font-size: 36px;
                display: flex;
                justify-content: flex-start; 
                align-items: center;
                margin-left: 25px; 
            }
        } 
 
        .slider-nav {
            bottom: -5px;
            &.bullets {
                li {
                    background: #fff;
                    border: 1px solid #fff;
                    border-radius: 0;  
                    width: 25px;
                    height: 15px; 
                    transition: .3s ease-in-out;
                    &.active { 
                        background: @greenColor; 
                        border-color: @greenColor;
                        height: 20px;
                    } 
                }
            }
        }
    }
}