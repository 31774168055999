.new-offers__products {
    margin: 35px 0 45px;
}


.newProduct {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2px;
    &__container {
        background: white;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 430px;
        padding: 9.5px 0;
    }  

    &__image {
        width: 100%;
        max-width: 170px;
        padding: 10px;
    }

    &__wrapper {
        padding: 15px;
        width: 100%;
    }
 
    &__header {
        .newProduct__link-wrap {
            .newProduct__title {
                font-size: 14px;
                line-height: 1.286;  
                text-align: left;
                color: black; 
                font-weight: normal;
            }
        }
    }

    &__price-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 10px;
    }

    &__price {
 
        .price__gross {
            display: flex;
            flex-direction: column; 
        }
        .price__current {
            font-size: 18px;
            font-weight: bold; 
            font-style: normal; 
            &--sale { 
                color: @redColor;
                order: -1;
            }
        }

    }

    &__buttons { 
        form {
            margin: 0;
            input {
                display: none!important; 
            }

            .addtobasket {
                background-color: @greenColor;
                border-radius: 5px;
                border: 0;  
                padding: 8px;
                transition: 0.3s ease-in-out;
                i {
                    color: white;
                    font-size: 18px;
                }

                &:hover {
                    background-color: @greenColor;
                    border-color: @greenColor;
                    box-shadow: 0px 3px 7px 0px rgba(1, 1, 1, 0.35);
                }
            }
        }

    }
}
