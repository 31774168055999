
.shop_index {
    .header-menu-wrapper {
        width: 100%;
        z-index: 1;
    
        @media screen and (min-width: 1440px) {
            position: absolute;
            top: 0;
            left: 0;
           
        }
    }  
}