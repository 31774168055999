#box_filter {
    .innerbox {
        .filter-name {
            width: 100%;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        .multiselect {
            width: 100%;
            margin-bottom: 25px;
            background: #ffffff;
            color: #000000;
            border: 1px solid black;
            border-radius: 5px;
            &::after { 
                content: "\f0d7";
                font-family: "Font Awesome 5 Free" !important;
                color: black;
                font-size: 16px;
                font-weight: bold; 
                position: absolute;
                top: 0;
                right: 0;
                padding: 12px;
                height: 40px; 
                display: flex;
                justify-content: center;
                align-items: center;
            }
            > span {
                padding: 0 1em;
                height: 40px;
                display: block;
                overflow: hidden;
            }
        }
    }
}