.footer__agroplastinfo {
    padding: 1.5rem 0 1.5rem 20px;
    @media screen and (max-width: 767px) {
        padding: 1.5rem 20px;
    }
    .agroplastinfo {
        display: flex;
        flex-direction: column;
        max-width: 290px;
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
        &__header {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap; 
            align-items: flex-start;
            img {
                margin-right: 10px; 
            }
            span {
                font-size: 11px;  
                font-weight: bold; 
                margin-top: 15px;
            }
        }
        &__content {
            margin-top: 15px;
            span {
                display: none;
                font-size: 11px;
                text-align: justify;
                line-height: 1.455;
            } 
        }
    }
}

.shop_index {
    .agroplastinfo { 
        &__header {
            align-items: center;
            flex-direction: row;
            span {
                margin-top: 0;
            }
        } 
        &__content {
            span { 
                display: block!important; 
            }
        }
    }
}