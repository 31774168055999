.bottom-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0!important; 
    @media screen and (max-width: 1150px) {
        flex-direction: column;
    }
    &__wrapper {
        display: flex;
        width: 100%;
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            justify-content: center;
            align-items: center; 
        }
    }
 
    .box {
        margin: 0;
    }
}