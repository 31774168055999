#box_productfull {
    .product-main-box {
        margin: 0 auto;
        max-width: 1180px;
    }
} 


.modal-body {
    .ajax-basket-container { 
        .ajax-product-block {
            .photo {
                display: none;
                &:first-of-type {
                    display: block;
                }
            }
        }
    }
}

.rwd.wrap .main {
    @media screen and (max-width: 767px) {
        margin: 0;
    }
}
