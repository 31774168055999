#products_bottom_modules {
    display: flex;
    @media screen and (max-width: 840px) {
        flex-direction: column;
    }  
    .new-offers {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        flex-basis: calc(100% * 2 / 7);
        margin-top: 30px;
        background-color: @backgroundBlock;  

        @media screen and (max-width: 1560px) {
            flex-basis: calc(100% * 2 / 6);
        }
        @media screen and (max-width: 1360px) {
            flex-basis: calc(100% * 2 / 5);
        } 

        > .btn--new-offers { 
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            max-width: 180px;
            padding: 15px 0;
            margin-top: 10px;
        } 
    }

    .recommended-categories { 
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: @greyColor;
        padding-top: 30px;
        flex-basis: calc(100% * 5 / 7);
        max-width: calc(100% * 5 / 7);
        @media screen and (max-width: 1560px) {
            flex-basis: calc(100% * 2 / 3 );
            max-width: calc(100% * 2 / 3 );
        } 
        @media screen and (max-width: 1360px) {
            flex-basis: calc(100% * 3 / 5 );
            max-width: calc(100% * 3 / 5 );
        }
        @media screen and (max-width: 840px) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}  