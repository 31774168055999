    .dropdown-wrapper {
        position: absolute;
        top: 7px;
        right: 25px;
        margin-top: 5px;
        z-index: 99;
        .language-dropdown {
            width: 155px;
            position: relative; 
            &::after {
                content: "\f0d7";
                font-family: "Font Awesome 5 Free"!important;
                font-size: 12px;
                font-weight: bold;
                position: absolute;
                top: 8px;
                right: 10px;
            }  
            .language-select { 
                display: flex;  
                height: 34px;
                padding: 6px 10px;
                position: relative;
                border: 1px solid black;
                font-size: 11px;
                cursor: pointer;
                .language-select-content {
                    pointer-events: none;
                }
            }
            .dropdown-menu {
                background: #fff;
                box-shadow: none;
                border-radius: 0px;
                position: absolute;
                width: 100%;
                max-width: 140px;
                z-index: 1;
                top: 40px; 
                list-style-type: none;
                font-size: 11px;
                li {
                    cursor: pointer;
                    padding:5px;
                }
            }
        }
        .language-disabled {
            pointer-events: none;
        }
        .language-hide {
            display:none;
        }
    }