#productstab {
    margin-top: 35px;
    overflow: hidden;
    ul {
        &.tabs {
            margin: 0px;
            padding: 0 0 0 30px;
            list-style: none;
            @media screen and (max-width: 840px) {
                padding: 0;
            }
            @media screen and (max-width: 535px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            li { 
                background: white;
                color: black;
                display: inline-block;
                padding: 15px 40px;
                font-size: 14.292px;
                font-weight: bold; 
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;    
                margin-right: 5px; 
                cursor: pointer;
                transition: .3s ease-in-out;
                &:hover {
                    background: @yellowColor; 
                }
                @media screen and (max-width: 1645px) {
                    padding: 15px 10px;
                }
                @media screen and (max-width: 1490px) {
                    font-size: 12px;
                }  
                @media screen and (max-width: 1360px) {
                    width: 100%;
                    max-width: 425px; 
                    border-radius: 0;
                } 
                @media screen and (max-width: 840px) {
                    max-width: 100%;
                    text-align: center;
                }
                &:last-of-type {
                    margin-right: 0;
                }
                &.current{ 
                    background: @yellowColor; 
                }
            }
        }  
    } 
    
    .tab-content { 
        display: none; 
        padding: 0 30px;
        margin-top: 2px;  
        max-width: 100%;
        overflow: hidden;
        @media screen and (max-width: 840px) {
            padding: 0;
        }
        &.current{ 
            display: block;
        }  

        .slider--products {
            .owl-theme {
                .owl-nav {
                    margin-top: -10px; 
                }
            } 
        }



    }

}