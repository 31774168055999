/*.toggler {
    display: none;

    &.toggler--menu {
        @media screen and ( max-width: 880px ) {
            display: block;

            + .menu {
                position: absolute;
                top: 100%;
                left: 0;

                display: none;
                max-width: 100vw;
                width: 90vw;
                max-height: calc(~'100vh - 80px');
                height: auto;
                overflow-x: hidden;
                overflow-y: auto;
            }

            // &.is-opened + .menu {
            //     display: block;
            // }
        }

        padding: 25px;
    }

    .toggler__icon {
        font-size: 24px;
    }
}*/


.toggler {
    display: none;
    transition: .5s ease-in-out;
    @media screen and (max-width: 650px) {
        display: flex;
        justify-content: center;
        align-items: center; 
        padding: 20px;
        font-size: 18px;
        line-height: 1;
        background: white;
        transition: .5s ease-in-out;
        &:hover {
            color: @greenColor;
        }
        i {
            margin-left: 10px;
            line-height: 1;
        }
    }

}