html {
    font-size: @fontSize;
}
body {
    background-color:  @backgroundBlock; 

    &.shop_login, &.shop_login_register, &.shop_basket, &.shop_contact, &.shop_basket_step2, &.shop_basket_address, &.shop_basket_step3, &.shop_basket_done {
        background-color: white;
    } 
}   
 
*,   
::after,  
::before { 
    box-sizing: border-box;
}   
   
a { 
    transition: opacity 0.3s ease-in-out; 
    outline: 0;
    &:hover {
        opacity: 0.7;
    }
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
}

::selection {
    background: @mainColor;
    color: @backgroundColor;
    opacity: 1;
}
 
.select-wrapper {
    position: relative;
    select {
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        cursor:pointer;
        background: none;
    }
}