.belt {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 105px;
    background: white;
    margin-bottom: 30px;
    @media screen and (max-width: 979px) {
        margin-top: 30px;
    }
    @media screen and (max-width: 979px) {
        margin-top: 30px; 
    }
    @media screen and (max-width: 840px) {
        padding: 15px; 
    }
    &__container {
        width: 100%;
        max-width: 1180px;
        margin: 0 auto; 
        display: flex;
        flex-wrap: wrap;
        align-items: center;  
        justify-content: space-between;
        @media screen and (max-width: 1220px) {
            justify-content: space-around;
        } 
    }   
 
    &__boxhead {
        .name {
            font-size: 23px; 
            color: @greenColor;
            font-weight: bold;
            line-height: 1.2; 
            margin: 0;
            @media screen and (max-width: 840px) {
                text-align: center;
            }
        }
    }
  
    &__codes {
        display: flex;
        flex-wrap: wrap; 
        margin-top: 5px;
        @media screen and (max-width: 840px) {
            justify-content: center;
            align-items: center;
        } 
        .code { 
            margin-right: 15px; 
            font-size: 12px;
            color:  @darkGreyColor;
            @media screen and (max-width: 840px) { 
                text-align: center;
            }   
            span {  
                font-weight: bold; 
            }
        }
    }

    &__prices {
        margin-right: 7%;
        .price-wrapper {
            display: flex; 
            flex-direction: column;
            align-items: flex-end;

            .price {
                display: flex;
                align-items: baseline;
                .main-price {
                    order: 1;
                    font-size: 32px;
                    font-weight: bold;
                    line-height: 1.2;
                    color: black;
                }
                
                del + em.main-price { 
                    color: @redColor; 
                }
                del[class*="none"] + em.main-price {
                    color: black;
                }
                del {
                    font-size: 14px; 
                    margin-right: 2px;


                }
                   
                .price-name {
                    display: none;
                }
            }
            .price-netto {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: 14px;
                line-height: 1.2;
                margin-left: 15px; 
                .price-name { 
                    margin-right: 5px;
                }
                .main-price {
                    font-weight: bold;
                }
                del {
                    display: none;
                }

            }
        }
    }
} 
  