header {
    border-top: 0; 
    padding: 0;
}


.header {
    &__main {
        background-color: @greenColor;
        min-height: 80px;
        display: flex; 
        flex-wrap: wrap;
        align-items: center;  
        justify-content: center;
        padding: 0 5px;
    }
     
    &__logo {
        flex-basis: 25%;
        @media screen and (max-width: 980px) {
            display: flex;
            flex-basis: 100%;
            width: 100%;  
            justify-content: center; 
            align-items: center;
            margin: 10px 0 0;
        }
        > .link-logo {
            &:hover {
                opacity: 1!important;
            }
        } 
    }

    &__search { 
        flex-basis: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @media screen and (max-width: 980px) {
            flex-basis: 100%;
            width: 100%;
            justify-content: center;
            margin: 10px 0;
        }
        .search-form {
            margin: 0; 
            width: 100%;
            max-width: 560px; 
            fieldset {
                border: 2px solid white;
                border-radius: 30px;
                position: relative;
                background-color: transparent;
                height: 45px;
                .search-input {
                    margin: 0;
                    width: 100%!important; 
                    border: 0;
                    border-radius: 30px;
                    background-color: transparent;
                    color: white; 
                    padding: 0 20px;
                    height: 42px;

                    &::placeholder { 
                        color: white; 
                        font-style: italic;
                    }
                }
                .search-btn {
                    position: absolute;
                    top: 1px;
                    right: 2px;
                    background-color: @yellowColor;
                    color: #000000;
                    border: 0;
                    border-radius: 50%;
                    font-size: 16px;
                    line-height: 1;
                    padding: 11px 12px;
                    transition: .3s ease-in-out;
                    &:hover {
                        color: white;
                    }
                } 
            }
        }
    }

    &__actions {
        flex-basis: 30%;
        display: flex;
        justify-content: flex-end;  
        align-items: center; 

        @media screen and (max-width: 980px) {
            flex-basis: 100%;
            width: 100%;
            justify-content: center;
            margin: 10px 0;
        }

        .action { 
            font-weight: bold;

            &--login { 
                .links {
                    a {
                        color: #fff; 
                        font-size: 16px; 
                        padding: 0 10px;
                        i {
                            font-size: 18px; 
                        }
                    }
                } 
            }

            &--basket {
                .basket {
                    background-color: @yellowColor;
                    padding: 10px 20px;
                    border-radius: 30px;

                    .count {
                        color: #000000;
                        font-size: 16px; 
                        transition: .3s ease-in-out;
                        &:hover {
                            color: #fff;
                        }
                        .countlabel {
                            display: flex;
                            align-items: center;
                            justify-content: center; 
                            i { 
                                font-size: 18px;
                                border-right: 1px solid black!important;
                                padding: 3px 13px 3px 3px;
                            }  
                            > b {
                                padding-left: 13px;
                                padding-right: 3px;
                                .count {
                                    display: none;
                                }
                            } 
                        }
                    }  
                } 
            }
        } 
    }
}

.shop_index { 
    .header {
        &__main { 
            background-color: @greenColor;
            @media screen and (min-width: 1440px) {
                background-color: rgba(3, 119, 0, .7); 
            }
                
        }

    } 
} 