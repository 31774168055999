.navigation--menu {
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
} 
 
  
.menu {
    display: flex; 
    justify-content: center;
    position: relative;

    background: #fff;
 
    padding: 0;
    margin: 0;  

    transition: .5s ease-in-out;
    
 
    @media screen and (max-width: 650px) {
        display: none;

        &--active {
            display: flex;
            transition: .5s ease-in-out;
        }
    }

    @media screen and (max-width: 650px) {
        flex-direction: column;
    }
    
    @media screen and ( max-width: 880px ) {
       // background: fade( @backgroundColor, 90% );
       // padding: 20px 0;
      //  border: 1px solid @lightBorderColor;
    }

    // First level menu bar
    > .item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 60px;

        @media screen and ( max-width: 880px ) {
            //display: block;
        }
        @media screen and (max-width: 650px) {
            height: 45px;
        }

        > .item__link {
            display: block;
            padding: 21px 1.3em;
            font-size: 16px; 
            color: #000;
            &:hover {
                color: @greenColor;
            }
            @media screen and (max-width: 1725px) {
                font-size: 15px;
                padding: 21px 1em!important;
            }
            @media screen and (max-width: 1625px) {
                font-size: 14px;
                padding: 21px .8em!important;
            }
            &:hover {
                text-decoration: none!important;
            }
            @media screen and ( max-width: 880px ) {
                text-align: left;
            }

            transition: color 0.3s ease-in-out,
                        background 0.3s ease-in-out;

            @media screen and ( min-width: 881px ) {
                &:hover {
             //       color: @backgroundColor;
                  //  background: @greenColor;
    
                    opacity: 1;
                }
            } 
 
            .item__label {
                font-size: 15px;
                font-weight: bold;
                line-height: 18px;
            }
        }

        &.item--parent {
            background: @yellowColor;
            box-shadow: 0px 4px 7px 0px rgba(1, 1, 1, 0.2);
            transition:  ease-in-out 0.3s;  
            @media screen and (max-width: 650px) {
                display: block;
            }
            > .item__link {
                display: flex;
                transition: ease-in-out 0.3s;
                            color: black; 
                            > .item__label { 
                                font-weight: bold;  
                            } 
                            @media screen and (max-width: 650px) { 
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 45px;
                            }
            } 
            .item__icon { 
                font-size: 18px; 
                font-weight: bold;
                color: black; 
                //background: #fff; 
                border-radius: 50%; 
               // padding: 3px 3px 0px;
                line-height: 1;
                margin-left: 10px;
                //box-shadow: 0px 4px 7px 0px rgba(1, 1, 1, 0.2);
                transition: ease-in-out 0.3s;
            }
            
            @media screen and ( min-width: 0px /*881px*/ ) {

            }

            @media screen and (min-width: 651px) {
                &:hover {
                    > .item__link {
                        background: @greenColor;
                        color: white;
                        opacity: 1;
                        
                        transition: color 0.3s ease-in-out,
                                    background 0.3s ease-in-out;
                    }
    
                    .item__icon {
                        transform: rotate( 180deg );
                        transition: 0.3s ease-in-out; 
                        color: white;
                    }
  
                } 
                &:hover {
                    .submenu__wrapper { 
                        visibility: visible!important;
                        opacity: 1!important;
    
                        transition: visibility 0s linear 0s,
                                    opacity 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}


.submenu {
    &__wrapper {
        @media screen and ( min-width: 0px /*881px*/ ) {
            position: absolute;
            top: 100%;
            left: 0;
            //width: 100%;
            z-index: 100;

            width: 100%;
    
            visibility: hidden;
            opacity: 0;
            
            transition: visibility 0s linear 0.6s,
                        opacity 0.3s ease-in-out 0.3s;
        }

        @media screen and (max-width: 650px) {
            position: relative; 
            margin-top: -45px;
        }

        &--visible {  
            visibility: visible!important;
            opacity: 1!important;
        }
    }

    display: flex;
    // justify-content: space-between;

    text-align: left;
    background: rgba(0,0,0,.9);
    padding: 20px 0;
   // border: 1px solid @lightBorderColor;

    @media screen and ( max-width: 1150px ) {
        display: block;
        //background: transparent;
        border: 0;
    }

    .item {
        .item__link {
            display: block;
            color: #fff;
            padding: 5px 0;

            &:hover {
                color: @greenColor;
            }
 
            .item__label {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                &.subcategory {
                    margin-left: 15px;
                }
                &.sonderangebote {
                    color: @redColor;
                }
            }
        }
    }

    .group {
        flex-basis: 25%;

        padding: 0 45px;

        &:last-child {
            flex-grow: 1;
        }
        &:not( :last-child ) {
      //      border-right: 1px solid @lightBorderColor;
        }
        .group__header {
            //margin-bottom: 5px;
            @media screen and ( max-width: 880px ) {
                margin-bottom: 0;
            }

            .item__label {
                font-weight: 700;
                font-size: 16px;
               // text-transform: uppercase;
            }
        }
        .group__items {
            @media screen and ( max-width: 880px ) {
                padding-left: 25px;
            }
        }
        &[data-category-id="000"] { 
            .group__items {
                columns: 2;

                @media screen and ( max-width: 880px ) {
                    columns: unset;
                }
            }
        }
    } 
} 
  
  
//.menu { 
//    background: #fff;
//    padding: 0;
//
//    .innermenu {
//        display: flex;
//        justify-content: center;
//        align-items: center;
//        .menu-list {
//            border-bottom: 0;
//            height: 58px;
//            li {
//                h3 {  
//                    font-size: 15px; 
//                    font-weight: bold;
//                    margin: 0;
//                    > a {
//                        padding: 19px 1.3em;
//                        &:hover {
//                            color: @greenColor;
//                            text-decoration: none;
//                        } 
//                    }
//                }
//                &.current {
//                    h3 {
//                        > a { 
//                            color: @greenColor; 
//                        } 
//                    }
//                }
//            }
//        }
//    } 
//} 
    