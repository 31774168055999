#shoper-foot {
    display: none;
}

.up {
    display: none;
}

.footer__copyright {
    background: white;
    padding: 0;

    .copyright {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 80px;
        padding: 0 80px;
        @media screen and (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            height: auto;
            min-height: 80px;
        }
        &__shoper {
            margin-right: 30px;
            @media screen and (max-width: 767px) {
                margin: 20px 0;
            }  
            > a { 
                font-size: 12px;
                color: rgba(0, 0, 0, 0.502); 
            } 
        }

        &__getecom { 
            display: flex; 
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 767px) {
                margin: 0 0 20px;
            }  
            span {
                color: #363636; 
                font-weight: bold;
                font-size: 12px;
                margin-right: 10px;
            }
            img { 
                width: 100%; 
                min-width: 100px; 
                max-width: 100px;   
            } 
        } 
    }
}
  