.tab-container {
    margin: 25px 0;
    
    nav {
       // background: @greenColor;
        margin-bottom: 0;
        ul {
            border-bottom: 0;
            max-width: 1180px;
            margin: 0 auto;
            li {
                padding: 0;
                margin-right: 12px;
                margin-bottom: 7px;
                .boxhead {
                    padding: 7px 45px; 
                    background-color: white; 
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    transition: .3s ease-in-out;
                    &:hover {
                        background: @yellowColor; 
                    }
                    h3 {
                        font-size: 14px;
                        font-weight: bold;
                        color: black;
                    } 
                    &.active {
                        border: 0;
                        background: @yellowColor; 
                       // box-shadow: 0px 3px 13px 0px rgba(1, 1, 1, 0.2);
                        padding: 7px 45px; 
                        h3 {
                            color: #000;
                        }
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .product-tabs {
        background: #fff;
        .product-tab {
            .innerbox {
                max-width: 1180px;
                margin: 0 auto;
                padding: 35px 0;
                @media screen and (max-width: 1220px) {
                    padding: 35px 15px;
                }
                p {
                    font-size: 14px;
                    line-height: 1.5;
                } 
            }
            .product-attributes {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .table {
                    border: 1px solid @lightGreyColor;
                    max-width: 900px;
                    td {
                        padding: 10px;
                    }
                }
            }
        }
    }
}
  