.alert {
    z-index: 1;
} 

.shop_index {
    .alert {
        @media screen and (min-width: 1441px) {
            margin-top: 145px;
        }
    }
}