.products-list__table {

    .box .innerbox .products--grid {
        width: 100% !important;
        margin: 0;

        @media screen and (max-width: 767px) {
            margin: 0;
        }
    }
}
@media screen and (max-width: 979px) and (min-width: 768px) {
    .rwd & {
        .s-grid-9 {
            width: auto !important;
        }
    }
}
@media screen and (max-width: 767px) {
    .rwd .main #box_mainproducts .products .product {
        padding: 0;
    }
} 

.products {
    &.products--grid {
        display: flex;
        flex-wrap: wrap;
        .product {
            flex-basis: calc((100% / 5) - 2px);
            margin: 2px 2px 2px 0;
            flex-grow: 0;
            max-height: 400px;

            @media screen and (max-width: 1310px) {
                flex-basis: calc(~'(100% / 4) - 2px');
            }
            @media screen and (max-width: 1150px) {
                flex-basis: calc(~'(100% / 3) - 2px');
            }
            @media screen and (max-width: 980px) {
                flex-basis: calc(~'(100% / 1) - 2px');
            }
            @media screen and (max-width: 480px) {
                flex-basis: calc(~'(100% / 1) - 2px');
            }
        }
    } 

    .product {
        transition: ease 0.3s;
        padding: 0;
        &:hover {
            opacity: .8;
        }
        .centercol & {
            border: 0;
        }

        &::before,
        &::after {
            display: none;
        }

        &:hover {
            z-index: 1;
        }

        height: 100%;

        background: @backgroundColor;

        .product__link-wrap {
            display: block;
            //padding: 1.5rem;

            &:hover {
                opacity: 1;
            }
        }

        .product__image {
            position: relative;
            height: 0;
            padding-bottom: 11rem;
            overflow: hidden;
            margin-top: 1rem;
        }

        .product__thumbnail {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);

            //max-width: 100%;
            max-width: 190px;
            max-height: 100%;
            width: auto;
            height: auto;
        }

        .product__header {
            padding: 0;
            margin: 1.5rem 0 .5rem; 
        }

        .product__title {
            height: 3.9em;

            font-size: 14px;
            font-weight: 400;
            line-height: 1.3em;
            text-align: center;

            overflow: hidden;

            padding: 0 1rem;
            margin: 0;

              
        }

        .product__price {
            text-align: center;

            &.price {
                .price__gross {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }
                .price__reduced {
                    color: @textColor;
                    font-size: 12px;
                    font-weight: 400; 
                    position: absolute;
                    bottom: -15px;
                    order: 1;
                }
                  
                .price__current {
                    color: black;
                    font-size: 26px;
                    font-weight: 900;

                    &.price__current--sale {
                        color: @redColor;
                    }
                }

                .price-netto {
                    display: none;
                }
            }
        }

        .product__buttons {
            margin: 1rem 0 .5rem;
            padding: .6rem 0;
            //background: @greenColor;
            text-align: center;

            .addtobasket {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                background-color: @greenColor;
                border: 1px solid @greenColor; 
                border-radius: 5px;
                color: #fff;
                font-size: 14px;
                font-weight: bold;
                padding: 8px 35px;
                text-transform: lowercase;
                transition: .3s ease-in-out;
                &:hover {
                    box-shadow: 0px 3px 7px 0px rgba(1, 1, 1, 0.35);
                }
                > i {
                    font-size: 17px;
                    margin-right: 10px;
                }
            } 
        }
          
        .product__tags {
            color: @backgroundColor;

            .tags {
                display: flex;
                padding-left: 0;
                top: 10px;
                left: 10px;
                width: 75px;
                justify-content: space-between;
                .tag {
                    border-radius: 12%; 
                    width: 35px;
                    height: 35px;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase; 
                    font-weight: bold;
                    &--sale {
                        background: @redColor;
                        order: 1;
                    }
                    &--new { 
                        background: @yellowColor; 
                    }
                }
            }
        }
    }
}