#box_newsletter {
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center;
    min-height: 125px;
    border-top: 1px solid @borderColor2;
    border-bottom: 1px solid @borderColor2;
    background-color: @greyColor;
    @media screen and (max-width: 840px) {
        flex-direction: column;
        padding: 10px 0;
    } 
    .boxhead {
        margin-bottom: 0; 
        border-bottom: 0;   
        @media screen and (max-width: 700px) {
            margin-bottom: 10px;
        } 
        span {  
            font-size: 24px;
            font-weight: bold;
            line-height: 1.25;
            margin: 0 30px 0; 
        } 
    }
    .innerbox {
        display: flex;
        form { 
            margin: 0;
            width: 100%;
            max-width: 930px;
            display: flex;
            align-items: center;
            @media screen and (max-width: 700px) {
                flex-direction: column;
            } 
            fieldset { 
                display: flex;
                align-items: center;
                width: 100%; 
                margin-right: 20px;
                @media screen and (max-width: 430px) { 
                    margin-right: 0;
                } 
                .inputs-wrapper {
                    display: flex;
                    @media screen and (max-width: 700px) { 
                        justify-content: center;
                    } 
                    @media screen and (max-width: 430px) { 
                        flex-direction: column; 
                        align-items: center;
                    } 
                    .newsletter-input {  
                        margin: 0 10px 0;
                        padding: 0 15px;
                        width: 245px;
                        height: 45px; 
                        border-color: black;
                        font-size: 12px;
                        color: black;
                        &::placeholder {
                            font-size: 12px;
                            color: black;
                        }
                    }
                      
                    .btn {   
                        width: 135px;
                        height: 45px;
                        background-color: @yellowColor; 
                        border-color: @yellowColor;
                        border-radius: 5px;
                        @media screen and (max-width: 430px) { 
                            width: 245px;
                            margin-top: 10px;
                        } 
                        img {
                            display: none;
                        }
                        span {
                            display: flex;
                            justify-content: center;
                            text-align: center;
                            color: black;
                            font-weight: bold;
                        }
                    }
                }

            }

            .newsletter-rodo { 
                display: flex; 
                .checkbox-rodo {
                    position: relative;
                    margin-bottom: 0;
                    border: 1px solid black;
                    background: white;
                    box-shadow: none;
                    width: 20px;
                    height: 20px;
                    min-height: 20px;
                    max-height: 20px;
                    padding: 9px; 
                    -webkit-appearance: none;
                    &::before {
                        content: '';
                        background: @yellowColor;
                        width: 14px;
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        height: 14px;
                        opacity: 0;
                        transition: opacity 0.2s ease-in-out;
                    }
                    &:checked {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
                span {
                    font-size: 10px;
                    font-weight: bold;
                    line-height: 1.5;
                    padding-left: 10px;
                }
            }
        }
    }
}  