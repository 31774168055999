.header__search {
    .search__container {
        width: 100%;
        max-width: 450px;

        .search__input-area {
            border-radius: 25px;
        }

        .search-btn {
            border-radius: 25px;
        }
    }
}