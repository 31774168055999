.breadcrumbs {
    padding: 25px 0 25px;
    background: @backgroundBlock;
    .innerbreadcrumbs {
        padding: 0 30px;
        display: flex;
        align-items: center;
        .raq {
         //   display: none;
            margin: 0!important;
            i {
                color: #959595;
            }
        }
        .breadcrumb-home { 
            padding: 0 5px;
            font-size: 14px;
            line-height: 1;
          
            display: flex;
            align-items: center;
            i {
                margin-right: 10px; 
                color: @greenColor;
                font-size: 16px; 
            }
            img {
                display: none;
            } 
            span {  
                display: block;
            }
        }
        .path {
            li {
                font-size: 14px;
                line-height: 1;
                padding: 0 0 0 3px;
                &.last {
                    border-right: 0;
                   // padding: 10px 20px; 
                    font-weight: bold; 
                }
                span { 
                    line-height: 1;
                }

            }
        }
    }
}
