.footer {
    background: url("/skins/user/rwd_shoper_3/images/footer-bg.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: @greenColor;
    padding-bottom: 0;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
        margin: 0!important;
    }
    .innerfooter {
        color: #fff;
        ul.overall {
            padding: 0 45px;
            li.overall {
                display: flex;
                justify-content: center; 
                align-items: center;
                border-top: 0;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                @media screen and (max-width: 767px) {
                    width: 100%!important;
                    padding: .5em 0;
                    border-left: 0!important; 
                } 

            }
            ul.links {
                @media screen and (max-width: 767px) {
                    width: 100%; 
                    border-bottom: 0;
                }
              .links__header {
                  background-color: transparent; 
                  font-size: 16px;
              }  
              .link {
                  padding: .3em 0;
                  > a {
                      color: #fff; 
                      font-size: 14px;
                  }  
                  @media screen and (max-width: 767px) {
                      background-color: @greenColor;
                  }
              }
            }
        }
    }
} 