.productinfo {
    background: #fff;
    min-height: 458px!important;
    display: flex!important;
    justify-content: center;
    padding: 60px 0 30px;
    @media screen and (max-width: 767px) {
        min-height: auto!important;
    }
    &__wrapper {
        max-width: 540px; 
        margin: 0 55px; 
        @media screen and (max-width: 1010px) { 
            margin: 0 25px; 
        }
    }

    &__boxhead { 
        &.boxhead {
            border-bottom: 0; 
        }
        .name {
            font-size: 27px;
            color: @greenColor;
        }
    }
    &__codes {
        border-bottom: 1px solid @borderColor;
        display: flex;
        width: 100%;
        padding-top: .3em;
        padding-bottom: 1em;
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .code {
            width: 30%;
            font-size: 11px;
            @media screen and (max-width: 1150px) {
                width: 100%;
                margin: 5px 0;
            }
            span {
                font-weight: bold;
            }
        }
        .codes {
            &__productcode {
                width: 40%;
            }
            &__ean {
                text-transform: uppercase;
            }
        }
    }  
    &__price {
        display: flex;
        justify-content: space-between; 
        align-items: center;
        padding: 1em 0;
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .price-wrapper {
            display: flex;
            align-items: center;

            .price {
                display: flex;
                align-items: baseline;
                .main-price {
                    order: 1;
                    font-size: 32px;
                    line-height: 1.2;
                    color: @redColor;
                }
                del {
                    font-size: 14px;
                    margin-right: 10px;
                }
                  
                .price-name {
                    display: none;
                }
            }
            .price-netto {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                line-height: 1.2;
                margin-left: 15px;
                del {
                    display: none;
                }

            }
        }


    }
    &__pack {
        border-bottom: 1px solid @borderColor;
        padding: 1em 0;
        font-size: 14px;
        line-height: 1.2;
        .availability-wrapper {
            display: flex;
            justify-content: space-around;
            @media screen and (max-width: 1150px) {
               // flex-direction: column;
            }
            .availability { 
                position: relative;
                display: flex; 
                align-items: center;
                &::before { 
                    content: "\f468"; 
                    font-family: "Font Awesome 5 Free"!important;
                    color: @greenColor;
                    font-size: 30px;  
                    font-weight: bold; 
                    margin-right: 15px;
                } 
                > div { 
                    display: flex;
                    flex-direction: column;
                }
                @media screen and (max-width: 1150px) {
                //    width: 100%;
                }
                .first { 
                    display: flex; 
                    align-items: center;  
                }
                .second {  
                    color: @greenColor;
                    font-weight: bold; 
                }
            }
            .delivery {
                position: relative;
                display: flex;
                align-items: center;
                &::before { 
                    content: "\f48b"; 
                    font-family: "Font Awesome 5 Free"!important;
                    color: @greenColor;
                    font-size: 30px; 
                    font-weight: bold;
                    margin-right: 15px;
                } 
                > div { 
                    display: flex;
                    flex-direction: column;
                } 
                @media screen and (max-width: 1150px) {
                 //   width: 100%;
                } 
                .first {
                    display: flex;
                    align-items: center;
                    margin-right: .5em;
                }
                .second {
                    color: @greenColor;
                    font-weight: bold;
                }
            }
        }
    }

    .basket {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .form-basket {
            margin-top: 10px!important;
            .stocks {
                margin-bottom: 2em!important;
                .stocks-wrapper {
                    width: 100%;  
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    align-items: center;
                    @media screen and (max-width: 480px) {
                        justify-content: center;
                    }
                }
                .stocks-label {
                    label {
                        padding: 0;
                        font-size: 13px!important;
                        padding-right: 30px;
                        @media screen and (min-width: 768px) and (max-width: 1010px) {
                            font-size: 14px!important;
                        }   
                        @media screen and (max-width: 480px) {
                            padding: 0; 
                        } 
                    } 
                }
                .stocks-options {
                    min-width: 250px;
                    @media screen and (min-width: 768px) and (max-width: 1010px) {
                        min-width: 200px;
                    }
                    .option_select {
                        position: relative;
                        &::before {
                            content: "\f0d7";
                            font-family: "Font Awesome 5 Free" !important;
                            color: #000000;
                            font-size: 16px;
                            font-weight: bold;
                            position: absolute;
                            top: 0; 
                            right: 0;
                            padding: 12px;
                        }
                        select {
                            background: transparent;
                            position: relative;
                            border-color: black;
                            border-radius: 5px;
                            &:hover {
                                background: transparent;
                                color: #000000;
                                border-color: @greenColor;
                            }

                        }
                    }
                }
            }
            .addtobasket-container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end; 
                @media screen and (max-width: 480px) {
                    flex-direction: column;
                }
                .quantity_wrap {
                    display: flex;
                    width: 100%!important;
                    max-width: 160px;
                    @media screen and (max-width: 480px) {
                        margin-bottom: 1rem;
                        margin-right: 0!important;
                    }
                    .number-wrap {
                        margin-right: 20px;
                        @media screen and (max-width: 480px) {
                            margin: 0;
                        }
                        #incdec {
                            display: flex; 
                            align-items: center; 
                            justify-content: space-around;
                            border: 1px solid black;
                            border-radius: 35px;
                            padding: 2px;
                            #down, #up {
                                width: 20px;
                                height: 20px;
                                color: #000000;
                                font-size: 18px;
                                font-weight: bold;
                                text-align: center;
                                border-radius: 50%;
                                line-height: 1;
                                user-select: none;
                                cursor: pointer;
                                &:hover {
                                    color: @greenColor;
                                }
                            }
                            #up {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        } 
                        .product-quantity { 
                            border: 0; 
                            text-align: center;
                            color: #000000; 
                            font-size: 18px;
                            font-weight: bold; 
                            margin: 0; 
                            padding: 0; 
                            outline: 0;
                            box-shadow: none!important;
                            -moz-appearance:textfield;
                        }
                    }
                    .unit {
                        display: none;
                    }
                }
                .button_wrap {
                    display: flex; 
                    min-width: 250px!important;
                    width: 100%!important;
                    max-width: 280px;
                    @media screen and (min-width: 768px) and (max-width: 1010px) {
                        min-width: 200px!important;
                        max-width: 200px;
                    }
                    .addtobasket {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: @greenColor;
                        border: 0;
                        border-radius: 35px;
                        min-height: 50px!important;
                        font-size: 14px;  
                        font-weight: bold; 
                        transition: .5s ease-in-out; 
                        &::before { 
                            content: '\f07a';
                            font-family: "Font Awesome 5 Free" !important;
                            color: #fff;
                            font-size: 17px;
                            font-weight: bold;
                            padding-right: 10px;
                        }
                        &:hover {
                            background-color: @greenColor;
                            border-color: @greenColor;
                            box-shadow: 0px 4px 20px 0px rgba(1, 1, 1, 0.4);
                        }
                    }
                }
            }
        }
        .question {
            &__button {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 175px;
                font-size: 12px;
                padding: 15px 0;
                &:hover {
                    color: @greenColor;
                    border-color: @greenColor; 
                }
                &::before {
                    content: "\f086";
                    font-family: "Font Awesome 5 Free"!important;
                    color: @yellowColor; 
                    font-weight: 600;  
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
        }
    }
}