.btn {
    &--yellow {
        background-color: @yellowColor;
        color: black;
        font-weight: bold;
        border: 0!important;
        border-radius: 5px;
        &:hover {
            background-color: @greenColor!important;
            color: white!important;
            span {
                color: white!important;
            }
        } 


    } 
}